.menu {
  position: absolute;
  width: 100%;
  background-color: var(--white);
  box-shadow: 0px 9px 13px 5px rgba(0,0,0,0.23);
}

.nav {
  display: flex;
  flex-direction: column;
}

.menu header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.menu svg {
  width: 1.5rem;
  height: 1.5rem;
}

.menu header svg {
  width: 2rem;
  height: 2rem;
}

.nav > a {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  border-top: 1px solid var(--grey);
  text-decoration: none;
  color: var(--black);
}

.nav > a svg {
  margin-right: 0.5rem;
}
