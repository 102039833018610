*,
::before,
::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

ol,
ul {
	list-style: none;
}

img,
svg,
video,
canvas,
picture {
  display: block;
  max-width: 100%;
}

input,
button,
select,
textarea {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
span {
  overflow-wrap: break-word;
}

/* Variables */
:root {
  /* Colours */
  --green: rgba(16, 132, 108, 1);
  --black: rgba(30, 30, 30, 1);
  --white: rgba(250, 250, 250, 1);
  --grey: rgba(230, 230, 230, 1);
  --darkGrey: rgba(135, 135, 135, 1);
}

/* General Styling */
* {
  font-family: sans-serif;
}
