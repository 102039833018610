.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem;
}

.navbar svg {
  width: 2rem;
  height: 2rem;
  fill: var(--white);
  cursor: pointer;
  transition: all ease-in-out 300ms;
}

.navbar svg:hover,
.navbar svg:active  {
  transform: scale(1.05);
  transition: all ease-in-out 300ms;
}
