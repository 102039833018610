.card {
  width: 90%;
  margin-top: 2rem;
  border-radius: 0.5rem;
  background-color: var(--white);
}

.card header {
  font-size: 0.75rem;
  font-weight: lighter;
  padding: 1rem 0.75rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  background-color: var(--black);
  color: var(--white);
}


.card main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem;
}

.card main img {
  max-width: 15rem;
}

.headshot {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding: 1rem;
  margin: 0.5rem 0rem;
  border-radius: 0.15rem;
  background-color: var(--grey);
}

.headshot img {
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
}

.details {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 0.25rem;
}

.details > div {
  display: flex;
  flex-direction: row;
  gap: 2.5%;
  width: 100%;
}

.details span {
  width: 42.5%;
  text-align: right;
  color: var(--darkGrey);
}

.details p {
  width: 55%;
}

.card footer {
  width: 100%;
  padding: 1rem 0.75rem;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  background-color: var(--grey);
}
